.main-visual__top {
  background: repeating-conic-gradient(#F7F7F7,#f7f7f7 3deg,#F0F0F0 3deg 6deg);
  padding: 16px 0 0;
}

.amount {
  background: #FD1F77;
  padding: 32px 0;
}


.line {
  padding-bottom: .15em;
  background: linear-gradient(#FFE100, #FFE100) 0 50%/10% 30% no-repeat;
  transition: background .8s;
  transition-timing-function: linear;
  text-decoration: none;
}

.line.active {
  background-size: 100% 35%;
}


.news {
  padding: 100px 0 50px;
  &__container {
    border: 2px solid #333;
    border-radius: 15px;
    padding: 50px 45px 40px;
    position: relative;
  }
  &__head {
    background: #FFE600;
    border: 2px solid #333;
    border-radius: 30px;
    display: inline-block;
    font-size: 24px;
    left: 50%;
    letter-spacing: .1em;
    min-width: 220px;
    padding: 6px 0 4px;
    position: absolute;
    text-align: center;
    top: 0;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
  }
  &__date {
    box-sizing: border-box;
    font-weight: bold;
    letter-spacing: .2em;
    padding-right: 10px;
    width: 150px;
  }
  &__title {
    font-size: 20px;
    letter-spacing: .2em;
    + p {
      margin-top: 16px;
    }
  }
  &__body {
    width: calc(100% - 150px);
    p {
      font-size: 16px;
    }
  }
}

.csr {
  &__container {
    background: repeating-linear-gradient(
      -45deg,
      rgba(95, 206, 153, 0.15),
      rgba(95, 206, 153, 0.15) 8px,
      rgba(0, 0, 0, 0) 8px,
      rgba(0, 0, 0, 0) 16px
    );
  }
  &__content {
    width: 1100px;
    margin: 0 auto;
    padding-top: 10px;
    letter-spacing: .4em;
  }
  &__person {
    width: 290px;
  }
  &__text {
    width: 520px;
    padding-right: 10px;
  }
  &__title {
    font-size: 35px;
    position: relative;
    border: 1px solid #000000;
    padding: 25px 0;
    background: #ffffff;
  }
  &__title::after {
    font-size: 15px;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 4px;
    left: 4px;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }
  &__title span {
    background: linear-gradient(#ffe300, #ffe300) 0 100%/100% 30% no-repeat;
    padding: 3px;
  }
  &__desc {
    padding-top: 30px;
    font-size: 24px;
    line-height: 1.8em
  }
  &__award {
    width: 260px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.catch {
  padding: 100px 0;
  position: relative;
  z-index: 1;
  &__title {
    font-size: 40px;
    letter-spacing: .2em;
    line-height: 1.6;
  }
  &__image {
    margin-top: 70px;
  }

  &:after {
    background: #fff;
    bottom: 0;
    content: '';
    display: block;
    height: 30px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    transform: translateY(50%) rotate(45deg);
    width: 30px;
  }

}

.issues {
  background: #F2F2F2;
  padding: 160px 0;
  position: relative;
  z-index: 0;
  &__item {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    &:before {
      background: url(../../images/pc/top/batsu.png) no-repeat center center / contain;
      content:"";
      display: block;
      height: 90px;
      width: 90px;
    }
    + .issues__item {
      margin-top: 60px;
    }
  }
  &__item-inner {
    box-sizing: border-box;
    width: calc(100% - 90px);
    padding-left: 12px;
  }
  &__title {
    font-size: 28px;
  }
  &__body {
    margin-top: 20px;
  }
}

.section__bottom-image  {
  margin-top: 60px;
}
.wakaranai__text {
  font-size: 40px;
  line-height: 1.6;
  margin-top: 40px;
  letter-spacing: .1em;
}

.merit {
  background: #FFE9F1;
  padding-bottom: 100px;
  .section__title {
    background: #FD1F77;
    position: relative;
    img {
      margin-top: -1.4%;
    }
    &:after {
      background: #F2F2F2;
      content: '';
      display: block;
      height: 30px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-50%) rotate(45deg);
      width: 30px;
    }
  }
  &__items {
    margin-top: 90px;
  }
  &__item {
    background: #fff;
    border-radius: 20px;
    padding: 56px 60px;
    + .merit__item {
      margin-top: 50px;
    }
  }
  &__title {
    font-size: 36px;
    line-height: 1.6;
    margin-top: 50px;
    letter-spacing: .1em;
  }
  &__subtitle {
    font-size: 28px;
    margin-top: 20px;
  }
  &__image {
    margin-top: 50px;
  }
  &__body {
    margin-top: 70px;
  }

}


.service {
  background: #FFFCE5;
  padding-bottom: 140px;
  .section__title {
    background: #FFE600;
    img {
      margin-top: -1.4%;
    }
  }


  .headline__border {
    background-image: radial-gradient(#f1f1f1 20%, transparent 20%),
                      radial-gradient(#f1f1f1 20%, transparent 20%);
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px;
    border-color: #FFE600;
    background-color: #fff;
    color: #333;
    font-size: 30px;
    padding: 50px 0;
  }
  &__content {
    margin-top: 90px;
  }
  .section {
    &__block {
      + .section__block {
        margin-top: 140px;
      }
    }
    &__body {
      margin-top: 50px;
    }
  }

  &__table {
    font-size: 22px;
    width: 100%;
    margin-top: 40px;
    th,td {
      padding: 16px;
    }
    th {
      background: #FFE600;
      border-bottom: 3px solid #fff;
    }
    td {
      background: #fff;
      border-bottom: 3px solid #CCCCCC;
    }
    tr:last-child {
      th,td {
        border-bottom:0;
      }
    }
  }
  .note {
    margin-top: 20px;
  }
  &__links {
    display: inline-block;
    margin-top: 40px;
    padding: 20px;
    background: #fff;
    border: 2px solid #ccc;
    .option-link__list {
      margin-top: 20px;
    }
  }
  .button__border {
    margin-top: 90px;
  }

}



.button__border {
  a {
    color: #333;
    display: block;
    padding: 30px 0;
    background: #fff;
    border: 4px solid #333;
    border-radius: 60px;
    font-size: 22px;
    letter-spacing: .1em;
    max-width: 480px;
    margin: 0 auto;
    text-align: center;
  }
  small {
    font-size: 0.9em;
    margin-top: 8px;
    display: block;
  }
  &.pink {
    a {
      background: #FD1F77;
    }
  }
}


.note {
  p {
    font-size: 12px;
    letter-spacing: 0;
  }
}


@keyframes float {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
}

.tokuten {
  background: #FFE9F1;
  padding-bottom: 100px;
  .section__title {
    background: #FD1F77;
    img {
      margin-top: -1.4%;
    }
  }
  &__head {
    margin: 90px auto 30px;
    animation: float 3s infinite linear;
  }
  &__item {
    background: #fff;
    border-radius: 20px;
    padding: 56px 60px;
    + .tokuten__item {
      margin-top: 50px;
    }
  }
  &__title-wrap {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  &__icon {
    display: block;
    height: 102px;
    width: 102px;
  }
  &__title {
    box-sizing: border-box;
    font-size: 30px;
    line-height: 1.4;
    letter-spacing: .1em;
    padding-left: 12px;
    width: calc(100% - 102px);
  }
  &__image {
    margin-top: 70px;
  }
  &__body {
    margin-top: 70px;
  }
}

.flow {
  background: #E5EEFF;
  padding-bottom: 100px;
  .section__title {
    background: #005AFF;
    img {
      margin-top: -1.4%;
    }
  }
  &__items {
    margin-top: 90px;
  }
  &__item {
    background: #fff;
    border: 3px solid #333;
    border-top: 0;
    padding: 50px;
    position: relative;
    + .flow__item {
      margin-top: 70px;
    }
    &:after {
      border-color: #333 transparent transparent transparent;
      border-style: solid;
      border-width: 20px 20px 0 20px;
      bottom: 0;
      content: '';
      display: block;
      height: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      transform: translateY(100%);
      width: 0;
    }
    &:last-child:after {
      display: none;
    }
  }
  &__title {
    font-size: 32px;
    padding: 30px 0;
    background: #005AFF;
    border-radius: 50px;
    color: #fff;
    letter-spacing: .1em;
  }
  &__subtitle {
    font-size: 32px;
    letter-spacing: .1em;
    margin-top: 50px;
  }
  &__image {
    margin-top: 60px;
  }
  &__body {
    margin-top: 60px;
  }
}

.closure {
  padding: 145px 0 0;
  .button__border {
    margin-top: 50px;
  }
}

.tel {
  margin-top: 180px;
}


@include mq {
  .main-visual.main-visual__top {
    padding: 16px 10px 20px;
  }
  .amount {
    padding: 16px 20px;
  }


  .news {
    padding: 34px 0 0;
    &__head {
      font-size: 14px;
      min-width: 125px;
    }
    &__container {
      border-radius: 7px;
      padding: 30px 25px 20px;
    }
    &__date {
      font-size: 14px;
      padding:0;
      text-align: center;
      width: 100%;
    }
    &__body {
      width: 100%;
      p {
        font-size: 14px;
      }
    }
    &__title {
      font-size: 17px;
      line-height: 1.4;
      margin-top: 10px;
      + p {
        margin-top: 10px;
      }
    }
  }


  .csr {
    &__container {
      margin-bottom: 20px;
    }
    &__content {
      width: 100%;
      align-items: flex-start !important;
      justify-content: center !important;
    }
    &__person {
      order: 2;
      width: 170px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        margin: 0 0 -20%;
      }
    }
    &__text {
      order: 1;
      width: 100%;
      margin: 20px;
      padding: 0;
    }
    &__title {
      font-size: 17px;
    }
    &__desc {
      font-size: 15px;
      padding-top: 20px;
    }
    &__award {
      order: 3;
      max-width: 130px;
    }
  }


  .catch {
    padding: 50px 0;
    &__title {
      font-size: 20px;
    }
    &__image {
      margin-top: 25px;
    }

    &:after {
      height: 20px;
      width: 20px;
    }
  }


  .issues {
    padding: 50px 0;
    &__item {
      display: block;
      &:before {
        height: 58px;
        margin: 0 auto;
        width: 58px;
      }
      + .issues__item {
        margin-top: 50px;
      }
    }
    &__item-inner {
      padding: 0;
      width: 100%;

    }
    &__title {
      font-size: 20px;
      margin-top: 20px;
      text-align: center;
    }
  }
  .wakaranai__text {
    font-size: 20px;
    margin-top: 20px;
  }



  .section__title {
    img {
      margin-top: 0 !important;
    }
    .inner {
      padding: 0;
    }
  }
  .merit {
    padding-bottom: 24px;
    .section__title {
      &:after {
        height: 20px;
        width: 20px;
      }
    }
    &__items {
      margin-top: 25px;
    }
    &__item {
      border-radius: 10px;
      padding: 24px 10px;
      + .merit__item {
        margin-top: 25px;
      }
    }
    &__num {
      img {
        width: 60px;
      }
    }
    &__title {
      font-size: 18px;
      margin-top: 25px;
    }
    &__subtitle {
      font-size: 14px;
      margin-top: 8px;
    }
    &__image {
      margin-top: 35px;
    }
    &__body {
      margin-top: 35px;
    }
  }

  .service {
    padding-bottom: 25px;
    &__content {
      margin-top: 25px;
    }
    .headline__border {
      font-size: 17px;
      padding: 30px 0;
    }
    .section__body {
      margin-top: 20px;
    }
    .button__border {
      margin-top: 25px;
    }
    .section__block + .section__block {
      margin-top: 45px;
    }
    &__table {
      font-size: 14px;
      th,td {
        vertical-align: middle;
        line-height: 1.4;
        vertical-align: middle;
        border-width: 1px;
      }
      td {
        white-space: nowrap;
      }
    }
    &__links {
      margin-top: 20px;
      padding: 16px 16px;
      p {
        font-size: 12px;
      }
    }

  }
  .button__border {
    a {
      border-width: 1px;
      font-size: 17px;
      padding: 20px 0;
      width: 90%;
    }
  }

  .tokuten {
    padding-bottom: 24px;
    &__head {
      margin: 18px auto 16px;
      img {
        width: 125px;
      }
    }
    &__item {
      border-radius: 10px;
      padding: 24px 10px;
      + .tokuten__item {
        margin-top: 24px;
      }
    }
    &__title-wrap {
      display: block;
    }
    &__icon {
      width: 60px;
      height: auto;
      margin: 0 auto;
    }
    &__title {
      font-size: 22px;
      margin: 16px 0 0;
      padding: 0;
      text-align: center;
      width: 100%;
    }
    &__image {
      margin-top: 30px;
    }
    &__body {
      margin-top: 35px;
    }
  }


  .flow {
    padding-bottom: 24px;
    &__items {
      margin-top: 25px;
    }
    &__item {
      padding: 22px;
      + .flow__item {
        margin-top: 30px;
      }
      &:after {
        border-width: 14px 14px 0 14px
      }
    }
    &__title {
      font-size: 18px;
      padding: 12px 0;
    }
    &__subtitle {
      font-size: 16px;
      letter-spacing: .0;
      margin-top: 20px;
    }
    &__image {
      margin-top: 35px;
      img {
        max-width: 270px;
        max-height: 107px;
        width: auto;
        height: auto;
      }
    }
    &__body {
      margin-top: 35px;
    }
  }
  .closure {
    padding: 35px 0 0;
  }
  .tel {
    margin-top: 55px;
  }
}

