$campaign-theme-color: #00D195;


.campaign-top {
  background: $campaign-theme-color;
  text-align: center;
  font-size: 38px;
  color: #fff;
  padding: 15px 0;
  letter-spacing: 0.2em;
}

.campaign {
  &__catch {
    background: $campaign-theme-color;
    padding: 20px 0;
    color:#fff;
    margin-top: 5px;
    p {
      font-size: 16px;
    }
  }
  &__overview {
    text-align: center;
    padding: 100px 0 0;
    h2 {
      font-size: 50px;
      letter-spacing: 0.2em;
    }
    &-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 40px -20px 0;
    }
    &-item {
      box-sizing: border-box;
      width: 50%;
      padding: 0 20px;
    }
  }

  &__intro {
    margin-top: 60px;
    p {
      font-size: 16px;
    }
  }
  &__detail-wrap {
    border: 4px solid #FD1F77;
    padding:  40px;
    max-width: 1040px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 60px;
    .note {
      margin-top: 50px;
    }
  }
  &__head {
    color: #fff;
    font-size: 30px;
    display: inline-block;
    background: #FD1F77;
    padding: 8px 24px;
    border-radius: 30px;
    @at-root * + .campaign__head {
      margin-top: 48px;
    }
  }
  &__detail-list {
    counter-reset: number 0;           /* number のカウンタを 0 にセット */
    margin-top: 36px;
  }
  &__detail-item {
    counter-increment: number 1;
    font-size: 28px;
    position: relative;
    padding-left: 40px;
    line-height: 1.8;
    position: relative;
    &:before {
      content: counter(number);
      display: inline-block;
      background: #000;
      color: #fff;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 20px;
      vertical-align: bottom;
      text-align: center;
      text-indent: 0;
      font-family: Oswald, sans-serif;
      position: absolute;
      left: 0;
      top:10px;
    }
    + .campaign__detail-item {
      margin-top: 16px;
    }
  }
  &__subhead {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.precaution {
  margin-top: 50px;
  margin-bottom: 50px;
  a {
    cursor: pointer;
  }
}
.detail-section {
  + .detail-section {
    margin-top: 30px;
  }
}

.tokuten-detail {
  padding-bottom: 90px;
  .section__title {
    background: #FD1F77;
    img {
      margin-top: -3%;
    }
  }
  .pink {
    color: #FD1F77;
  }
}


.campaign-contact {
  background: #FFE600;
  color: #000;
  padding: 52px 0 40px;
  &__head {
    font-size: 40px;
  }
  .round-border {
    border: 2px solid #000;
    border-radius: 30px;
    display: inline-block;
    text-align: center;
    padding: 8px 40px;
    font-size: 24px;
    margin-top: 26px;
  }
  &__tel {
    font-size: 70px;
    font-family: Oswald, Avenir Next, Verdana, sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 26px;
    letter-spacing: 0.04em;
    &:before {
      content: "";
      display: block;
      width: 66px;
      height: 66px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIYAAACGCAMAAAAvpwKjAAAAV1BMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACeUznSAAAAHXRSTlMAID9/j7/v/8+vTzBv3xCfX0BQYICQoHDgwNDwsDX2/gEAAAPdSURBVHgBxdoJoqssDAXgw20leq2xCI50/+v85+G9lyqoNd8KTmlkCtjLfN3uxe8s/a4siqK6fX/hSvWjKknWFPdvxueZR2VpQ3H77LCYW0NpbPuNz+BnQTmau8HpTEv5yqdCCElzYhBX0O+Ug5iKjmk6HHezdFhlcIwr6Qz2hgP4TmdpauxVl3SiB/bxlk5VMfJxS4Kr/xhT0gd45KktfYRXSCFpM4tTP4enTyo0UghajboQtBopBAFbjJDi+u+WS7pEh1UtXcMahY9EUGiUpyBoFIbA4Y2ermQZIkfXGiBq6GIOgkBXKzWmT8GIX1R0PWtS6lOhSgtSYfADQzoCfjCQDssKgyEIyoMhDAeTHi9uMzSn0oIUmTMKdJrneTmnSAPli3PwNf7DLgz74jS7V/iXZ4jqfkeUGn+qKUccOsYKM74oT48/jZRuGhmbTB93fCvp6RePNDxOlI7xB0tpJocMPj1Il1EaMSBTiJSmTy+Nl0E2HihJmVoascMu9UQp8LsyoTTNWgfyi48OiANAmwaGgL/vRUl/s8X9WUPSxaRV1uy6E6nvwiDa4ikENkvKsuJoXazxM36U9E4ldByHzfoHQm4K0271Tjj3jF4CfV4KvtEGKYindcCcleJh9/X5fNwXQ07hd5/WO1rDmHO+EUOpOOtazWHOOnBPlGbKu95zsFm3MS9KMyMrhwO9s0AwHrnn6/fEMBDUh26Bp/wYA0SRkhhIfH6MANFMKSJE/D7Gknd7GtIrVJD/wYYjl6fhtBgDZPkVmlDg5m0MyweKw2Tehq9MSO3+i/WYORgEhMxOVLe7QrlZmfq7zA4Q07Y+s3M1r5Z+uXc0Cgj8euy8X9VZStBy1p38CGDK+PC42v26qdzYI83pHaCn3f12pd/6vvvUf9kUlMN2qRUVt2suyLvhzOdebGnFK+ELdKlDIbCPpBtPnzA9N3zkLVxhEpZlk7J2V4cegNnb5pZtSlu7i5aOKO82rcUUSZW/vo0hiCx81Jptv4kUdfJMe7FJs9Umr+KLbldHv8s1y6dL3ccTXn8wNIfDaTy1SjhJTPqDoTWjv+RrrYtFg1+ZSBcLkAS91USzSh1ktcaaJhjpOgveeyl8JQKert9zaZZHj3VeqzAUynRhbBo0UijkiDWSzAopBDzoprjgf1lqZOg1qlPgNVIIunj93CkxC+VLfxKjVSCLwU5d1P1DTp9BJodD3ETHxYDDQqSDXgYnMAMdMTucxAxRIYSAQ1QIIWC/UJ7Y1/gE00+UbOjwOS7psXAcPOPDuFuNEl9jjYuw82H+OUyc++AMFNTud2Pnfsc44jcJY3FbKTOI6gAAAABJRU5ErkJggg==') no-repeat center center / contain;
      margin-right: 10px;
    }
    a {
      color: #000;
    }
  }
  &__time {
    font-size: 26px;
    margin-top: 30px;
  }
  &__note {
    margin-top: 26px;
    display: inline-block;
    text-align: left;
    p {
      font-size: 16px;
      line-height: 1.6;
    }
  }
}


.campaign-content + .footer {
  margin-top: 0;
}

@include mq {
  .campaign-top {
    font-size: 20px;
    line-height: 1;
    padding: 12px 0;
  }
  .campaign-main {
    .inner {
      padding: 0;
    }
  }
  .campaign {
    &__catch {
      padding: 20px 24px 24px;
      p {
        font-size: 14px;
      }
      .inner {
        padding: 0;
      }
    }
    &__overview {
      padding: 36px 0 16px;
      h2 {
        font-size: 28px;
      }

      &-items {
        padding: 0 10px;
        margin: 0;
      }
      &-item {
        width: 100%;
        padding: 0;
        margin-top: 38px;
      }
    }
    &__intro {
      margin-top: 40px;
      p {
        font-size: 14px;
      }
    }
    &__head {
      font-size: 20px;
    }
    &__detail-wrap {
      padding: 40px 28px;
    }
    &__detail-item {
      font-size: 20px;
      display: block;
      padding-left: 0;
      line-height: 1.6;
      text-align: center;
      &:before {
        position: static;
        display: block;
        margin: 0 auto 10px;
      }
    }
    &__subhead {
      font-size: 18px;
    }
  }
  .precaution {
    font-size: 12px;
  }

  .tokuten-detail {
    .section__title {
      img {
        margin-top: -5% !important;
      }
    }
    .inner {
      padding: 0 20px;
    }
  }

  .campaign-contact {
    .inner {
      padding: 0 20px;
    }
    &__head {
      font-size: 28px;
      line-height: 1.4;
    }
    .round-border {
      font-size: 18px;
      padding: 10px 0;
      max-width: 90%;
      margin-top: 20px;
      width: 100%;
    }
    &__tel {
      font-size: 40px;
      &:before {
        width: 38px;
        height: 38px;
      }
    }
    &__time {
      font-size: 20px;
      margin-top: 16px;
    }
    &__note {
      p {
        font-size: 12px;
      }
    }
  }

}